<template>
  <div :key="getKey()" :class="{ lighten2: !count }">
    <div class="flix-col-md-3">
      <datesList :dates="info" :key="JSON.stringify(info)" />
    </div>
    <div class="flix-col-md-9">
      <flixLoader v-if="!loaded" />
      <div class="panel" :style="{ 'border-color': color }" v-else>
        <div
          class="title"
          :style="{ color: color }"
          v-html="assistent.title"
        ></div>
        <ul v-for="hour in Array(24).keys()" :key="hour">
          <li v-for="minutes in Array(4).keys()" :key="hour + '' + minutes">
            <div class="hour" :style="{ color: color }">
              <a
                href="#"
                :style="{ color: color }"
                :title="
                  $t('message.add', { name: $tc('message.appointments', 1) }) +
                  ' – ' +
                  $getNullsBefore(hour) +
                  ':' +
                  $getNullsBefore(minutes * 15) +
                  ' ' +
                  $t('message.oClock')
                "
                @click.prevent="addNewClient(hour, minutes * 15)"
                >{{ hour }}:{{ $getNullsBefore(minutes * 15) }}</a
              >
              <span class="description" v-if="minutes == 0">{{
                assistent.title
              }}</span>
            </div>
            <a
              href="#"
              @click.prevent="
                () => {
                  $router.push({
                    name: 'dashboardCRM',
                    params: { id: assistent.ID }
                  })
                }
              "
              v-if="
                active[
                  $getNullsBefore(hour) + '' + $getNullsBefore(minutes * 15)
                ]
              "
              :title="
                getTitle(
                  $getNullsBefore(hour) + '' + $getNullsBefore(minutes * 15)
                )
              "
              class="active"
              :class="getClass(minutes)"
              :style="{ left: 50 + 'px', 'background-color': color }"
            >
              <p
                class="description"
                v-if="
                  checkLast(
                    $getNullsBefore(hour),
                    $getNullsBefore(minutes * 15)
                  )
                "
              >
                {{ assistent.title }}
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { color: String, assistent: Object, date: Date, callback: Function },
  components: {
    datesList() {
      return import('@/components/bookingCalendar/list')
    }
  },
  data() {
    return {
      count: 0,
      active: {},
      list: [],
      info: {},
      loaded: false,
      bookings: this.$store.getters.bookings
    }
  },
  mounted() {
    this.getCheckDates(
      function () {
        this.loaded = true
      }.bind(this)
    )
  },
  methods: {
    getCheckDates(callback) {
      for (var hour = 0; hour < 24; hour++) {
        for (var minutes = 0; minutes < 60; minutes += 5) {
          this.checkDate(hour, minutes)
        }
      }
      callback()
    },
    addNewClient(hour, minutes) {
      this.$store.commit('setNewBooking', {
        assistent: this.assistent.ID,
        date: this.date,
        hour: hour,
        minutes: minutes
      })
      this.$router.push({
        name: 'dashboardAddCustomer',
        params: { id: this.assistent.ID }
      })
    },
    getTitle(time) {
      var l = this.info[time].length
      var r = this.assistent.title + ''
      this.info[time].forEach(function (v, k) {
        r += '\n'
        r +=
          k +
          1 +
          '/' +
          l +
          ') ' +
          ' #' +
          v.booking_id +
          ' | ' +
          v.email.split(' ')[0] +
          ''
      })
      return r
    },
    checkLast(hour, minutes) {
      var newDate = new Date(this.date.valueOf())
      newDate.setHours(hour)
      newDate.setMinutes(minutes)
      newDate.setMinutes(newDate.getMinutes() - 15)

      if (
        typeof this.active[
          this.$getNullsBefore(newDate.getHours()) +
            '' +
            this.$getNullsBefore(newDate.getMinutes())
        ] === 'undefined' ||
        this.active[
          this.$getNullsBefore(newDate.getHours()) +
            '' +
            this.$getNullsBefore(newDate.getMinutes())
        ] === false
      ) {
        return true
      }

      return false
    },
    setClick(hour, minutes) {
      var newDate = new Date(this.date.valueOf())
      newDate.setHours(hour)
      newDate.setMinutes(minutes)

      this.callback({ date: newDate, ID: this.assistent.ID })
    },
    getKey() {
      if (
        typeof this.bookings === 'undefined' ||
        typeof this.bookings[this.assistent.ID] === 'undefined'
      ) {
        return false
      }

      return this.bookings[this.assistent.ID][0].ID
    },
    getClass(id) {
      var r = {}
      r['active_' + id] = true
      return r
    },
    checkDate(hours, minutes) {
      if (
        typeof this.bookings === 'undefined' ||
        typeof this.bookings[this.assistent.ID] !== 'object'
      ) {
        this.active[
          this.$getNullsBefore(hours) + '' + this.$getNullsBefore(minutes)
        ] = false
        return false
      }

      var error = true

      var checkDate = new Date(this.date.valueOf())
      checkDate.setHours(hours)
      checkDate.setMinutes(minutes)

      this.bookings[this.assistent.ID].forEach(
        function (data) {
          if (data.status === 'cancellation') {
          } else if (
            this.$createDate(data.begin).getTime() <= checkDate.getTime()
          ) {
            if (this.$createDate(data.end).getTime() < checkDate.getTime()) {
            } else if (
              this.$createDate(data.end).getTime() !==
                this.$createDate(data.begin).getTime() &&
              this.$createDate(data.end).getTime() === checkDate.getTime()
            ) {
            } else {
              error = false
              this.active[
                this.$getNullsBefore(hours) + '' + this.$getNullsBefore(minutes)
              ] = true

              if (
                typeof this.info[
                  this.$getNullsBefore(hours) +
                    '' +
                    this.$getNullsBefore(minutes)
                ] === 'undefined'
              ) {
                this.info[
                  this.$getNullsBefore(hours) +
                    '' +
                    this.$getNullsBefore(minutes)
                ] = []
              }
              this.info[
                this.$getNullsBefore(hours) + '' + this.$getNullsBefore(minutes)
              ].push(data)
              this.count = 1
            }
          }
        }.bind(this)
      )

      return !error
    }
  }
}
</script>

<style scoped lang="sass">

.lighten
  opacity: 0.3

.description
  color: lightgrey
  font-size: 6pt
  text-decoration: none
  text-align: left

.active p.description
  text-align: left
  box-sizing: border-box
  display: block
  color: #fff
  display: block
  position: absolute
  width: 100%
  overflow-wrap: break-word
  word-wrap: break-word
  word-break: break-all
  hyphens: auto
  overflow-y: auto
  padding: 0 3px

.title
  font-weight: bold
  font-size: 9pt
  word-break: normal
  overflow: hidden
  box-sizing: border-box
  display: block
  margin-right: 20px

  @media (max-width: 500px)
    overflow: auto
ul
  margin: 0
  margin-top: 0
  padding: 0
  overflow-y: hidden
  overflow-x: hidden

  &:first-of-type
    border-top: 1px solid grey

  li
    list-style: none
    padding: 0
    border-bottom: 1px dotted grey
    width: 100%
    position: relative
    height: 18px
    color: grey
    font-size: 7pt
    box-sizing: border-box

    &:first-child
      font-size: 10pt
      opacity: 1

      .hour
        padding-top: 1px

    .hour
      display: inline-block
      width: 50px
      font-weight: bold
      padding-top: 3px

    .active
      display: inline-block
      width: calc(100% - 65px)
      height: 18px
      background-color: #4A148C
      padding: 0
      margin-right: 5px
      position: absolute
      box-sizing: border-box
      z-index: 1
      text-decoration: none
</style>
