var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.getKey(),class:{ lighten2: !_vm.count }},[_c('div',{staticClass:"flix-col-md-3"},[_c('datesList',{key:JSON.stringify(_vm.info),attrs:{"dates":_vm.info}})],1),_c('div',{staticClass:"flix-col-md-9"},[(!_vm.loaded)?_c('flixLoader'):_c('div',{staticClass:"panel",style:({ 'border-color': _vm.color })},[_c('div',{staticClass:"title",style:({ color: _vm.color }),domProps:{"innerHTML":_vm._s(_vm.assistent.title)}}),_vm._l((Array(24).keys()),function(hour){return _c('ul',{key:hour},_vm._l((Array(4).keys()),function(minutes){return _c('li',{key:hour + '' + minutes},[_c('div',{staticClass:"hour",style:({ color: _vm.color })},[_c('a',{style:({ color: _vm.color }),attrs:{"href":"#","title":_vm.$t('message.add', { name: _vm.$tc('message.appointments', 1) }) +
                ' – ' +
                _vm.$getNullsBefore(hour) +
                ':' +
                _vm.$getNullsBefore(minutes * 15) +
                ' ' +
                _vm.$t('message.oClock')},on:{"click":function($event){$event.preventDefault();return _vm.addNewClient(hour, minutes * 15)}}},[_vm._v(_vm._s(hour)+":"+_vm._s(_vm.$getNullsBefore(minutes * 15)))]),(minutes == 0)?_c('span',{staticClass:"description"},[_vm._v(_vm._s(_vm.assistent.title))]):_vm._e()]),(
              _vm.active[
                _vm.$getNullsBefore(hour) + '' + _vm.$getNullsBefore(minutes * 15)
              ]
            )?_c('a',{staticClass:"active",class:_vm.getClass(minutes),style:({ left: 50 + 'px', 'background-color': _vm.color }),attrs:{"href":"#","title":_vm.getTitle(
                _vm.$getNullsBefore(hour) + '' + _vm.$getNullsBefore(minutes * 15)
              )},on:{"click":function($event){$event.preventDefault();return (() => {
                _vm.$router.push({
                  name: 'dashboardCRM',
                  params: { id: _vm.assistent.ID }
                })
              }).apply(null, arguments)}}},[(
                _vm.checkLast(
                  _vm.$getNullsBefore(hour),
                  _vm.$getNullsBefore(minutes * 15)
                )
              )?_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.assistent.title)+" ")]):_vm._e()]):_vm._e()])}),0)})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }